<template>
  <v-app id="inspire">
    <v-content>
      <v-container fluid class="pa-0" style="height: 100vh">
        <v-layout fill-height class="pa-0">
          <v-row class="no-gutters">
            <v-col
              sm="0"
              md="7"
              lg="7"
              class="img-back-login"
              style="position: relative"
            >
              <h1 class="login-main-title">BIENVENIDO,</h1>
              <h2 class="login-main-subtitle">COMPONENTES PLANES DE VIDA</h2>
              <div class="modules-links">
                <span>CENSO INDÍGENA</span>
                <span>PLANEACIÓN</span>
                <span>DERECHOS HUMANOS</span>
                <span>JUSTICIA PROPIA</span>
              </div>
            </v-col>
            <v-col sm="12" md="5" lg="5" class="text-center login-form">
              <img
                src="../assets/images/common/logo-cric-login.png"
                class="img-logo-login"
                alt="CRIC"
              />
              <h1 class="login-title-h1">
                SISTEMA DE INFORMACIÓN DE GOBIERNO PROPIO
              </h1>
              <h2 class="login-title-h2">Inicio de sesión</h2>
              <v-flex md8 offset-md-2>
                <v-text-field
                  prepend-icon="mdi-account"
                  label="Documento de identidad"
                  required
                  v-model="credentials.username"
                ></v-text-field>
                <v-text-field
                  prepend-icon="mdi-key"
                  label="Contraseña"
                  required
                  type="password"
                  v-model="credentials.password"
                ></v-text-field>
              </v-flex>
              <v-btn
                dark
                color="#43794E"
                rounded
                x-large
                autofocus
                @click="login"
                style="min-width: 200px;!important; margin-top: 10px;"
                >INICIAR</v-btn
              >
              <p class="cr-legend">Todos los derechos reservados 2022</p>
            </v-col>
          </v-row>
        </v-layout>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import sweetalert from "sweetalert2";
export default {
  components: {},

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
    credentials: {
      username: "",
      password: "",
    },
  }),
  computed: {
    ...mapState(["Customizer_drawer"]),
  },
  methods: {
    ...mapMutations({
      setCustomizerDrawer: "SET_CUSTOMIZER_DRAWER",
    }),
    async login() {
      try {
        this.showLoader();
        await this.$store.dispatch("login", this.credentials);
        this.hideLoader();
      } catch (err) {
        console.error(err);
        this.hideLoader();
        if (err.status === 401) {
          sweetalert.fire("Usuario o contraseña, incorrectos","", "error");
        } else if (err.status === 500) {
          sweetalert.fire(
            "Ha ocurrido un error al procesar la solicitud",
            '',
            "error"
          );
        }
      }
    },
  },
};
</script>
<style scoped>
.login-main-title {
  color: #ffffff;
  font-size: 48px;
  font-weight: bold;
  position: absolute;
  bottom: 185px;
  left: 40px;
}

.login-main-subtitle {
  color: #ffffff;
  font-size: 32px;
  font-weight: normal;
  position: absolute;
  bottom: 150px;
  left: 40px;
  border-bottom: 3px solid #dd3333;
}

.img-back-login {
  background: url("../assets/background/img-login-web.png");
  background-size: cover;
}

.img-logo-login {
  margin-top: 25px;
  max-width: 359px;
  height: 230px;
}

.login-title-h1 {
  margin-top: 35px;
  text-align: center;
  font: normal normal bold 28px/43px Roboto;
  letter-spacing: 0.03px;
  color: #43794e;
  opacity: 1;
  padding-left: 25px;
  padding-right: 25px;
}

.login-title-h2 {
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 21px;
  font-weight: bold;
  color: #dd3333;
}

.login-form {
  background: #f6f7ff 0% 0% no-repeat padding-box;
  text-align: center;
  position: relative;
}

.cr-legend {
  color: #222222;
  display: block;
  width: 100%;
  margin-top: 20px;
}

.modules-links {
  text-align: left;
  position: absolute;
  font: normal normal normal 24px/29px Roboto;
  letter-spacing: 0px;
  color: #ffffff;
  font-size: 16px;
  bottom: 100px;
  left: 40px;
}

.modules-links span:not(:last-child):after {
  content: " • ";
}

@media only screen and (max-width: 959px)
{

  .img-back-login {
    min-height: 270px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .img-back-login h1.login-main-title {
    color: #fff;
    font-size: 22px;
    bottom: 185px;
    left: 0px;
    text-align: center;
    width: 100%;
  }

  .img-back-login h2.login-main-subtitle {
    color: #fff;
    font-size: 22px;
    bottom: 130px;
    left: 0px;
    text-align: center;
    width: 100%;
  }

  .img-back-login .modules-links{
    font-size: 16px;
    bottom: 60px;
    left: 0px;
    text-align: center;
    padding: 0 20px;
    width: 100%;
  }

  .text-center.login-form {
    padding: 20px 40px!important;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .login-title-h1 {
    font: normal normal 700 22px/32px Roboto;
  }

}
</style>
